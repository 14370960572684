<template>
    <span data-bs-toggle="tooltip"
         :data-bs-trigger="trigger"
         :data-bs-placement="placement"
         :data-bs-html="html"
         :title="content"
          ref="tooltip"
        >
        <slot></slot>
        <font-awesome-icon v-if="icon" :icon="icon" :transform="iconTransform" />
        <span v-else>{{ text }}</span>
    </span>
</template>

<script>
import Tooltip from "bootstrap/js/dist/tooltip";

export default {
    name: "Tooltip",
    props: {
        html: {
            type: Boolean,
            default: false,
            description: "If content should be displayed as html"
        },
        content: {
            type: String,
            default: "",
            description: "Content of the Popover"
        },
        icon: {
            type: String,
            default: null,
            description: "Displayed font-awesome icon. Use this or text"
        },
        iconTransform: {
            type: String,
            default: null,
            description: "Transform for font-awesome icon."
        },
        text: {
            type: String,
            default: null,
            description: "Displayed text. Use this or icon"
        },
        trigger: {
            type: String,
            default: "hover focus",
            description: "Use click | hover | focus"
        },
        placement: {
            type: String,
            default: "auto",
            description: "Use auto | top | bottom | left | right"
        },
        hidden: {
            type: Boolean,
            default: false,
            description: "If the tooltip should be hidden"
        }
    },

    methods: {
        checkHidden() {
            if (this.hidden) {
                Tooltip.getInstance(this.$refs.tooltip).disable();
            } else {
                Tooltip.getInstance(this.$refs.tooltip).enable();
            }
        }
    },

    watch: {
        content() {
            Tooltip.getInstance(this.$refs.tooltip).setContent({ '.tooltip-inner': this.content })
        },
        hidden() {
            this.checkHidden();
        }
    },

    mounted() {
        new Tooltip(this.$refs.tooltip);
        this.checkHidden();
    },

    beforeUnmount() {
        Tooltip.getInstance(this.$refs.tooltip).dispose();
    }
}
</script>

<style scoped>
</style>